import { axiosClient } from '../utils/axiosClient';

const addClientEndpoint = 'v1/client/add';
const checkIfMobileExistsEndpoint = 'v1/client/mobile_exists';
const checkIfEmailExistsEndpoint = 'v1/client/email_exists';
const faqEndPoint = 'v2/clients/faq/dropdown/values';

export const getClients = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    branch,
    premiumType,
    rewm,
    branchHead,
    rm,
    createdFromDate,
    createdToDate,
    customerType,
    paymentFromDate,
    paymentToDate,
    revenueCollectedBy,
    revenueType,
    channelPartner,
    channelPartnerVertical,
    country,
    deleted,
    ekyc,
    insights,
    referralType,
    appDownloads,
    sortAt,
    sortBy,
    updatedFromDate,
    updatedToDate
  } = filters;

  const params = {
    app_downloads: appDownloads, //  total, android, ios
    branch,
    branch_head: branchHead,
    channel_partner: channelPartner,
    channel_partner_vertical: channelPartnerVertical,
    country,
    deleted, // total, deleted,
    ekyc, // total, android, ios
    fee_paid_from_date: paymentFromDate,
    fee_paid_to_date: paymentToDate,
    from_date: createdFromDate,
    insights: insights, //  total, verified, jarvis
    limit,
    offset: limit * page,
    portfolio_manager: rewm,
    premium_type: premiumType,
    referral_type: referralType,
    revenue_collected_by: revenueCollectedBy,
    revenue_type: revenueType,
    rm_name: rm,
    sort_at: sortAt, // created_At, updated_at,
    sort_by: sortBy, // asc, desc,
    to_date: createdToDate,
    type: customerType,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get('v1/clients' + queryString).then((resp) => resp?.data);
};

export const getClientDetails = (clientId) => {
  return axiosClient.get(`v1/client/details/${clientId}`).then((resp) => resp?.data);
};

export const getClientInsights = (clientId) => {
  return axiosClient.get(`v1/client/${clientId}/insights`).then((resp) => resp?.data);
};

export const assignPortfolioManager = (clientId, assignedTo) => {
  return axiosClient
    .put(`v1/client/${clientId}/assign_rm`, { assigned_to: assignedTo })
    .then((resp) => resp?.data);
};

export const addClient = (params) => {
  return axiosClient.post(addClientEndpoint, params).then((resp) => resp?.data);
};

export const updateClient = (clientId, params) => {
  return axiosClient.put(`v1/client/${clientId}/update`, params).then((resp) => resp?.data);
};

export const deleteClient = (clientId) => {
  return axiosClient.delete(`v1/client/${clientId}/delete`).then((resp) => resp?.data);
};

export const getClientComments = (clientId) => {
  return axiosClient.get(`v1/client/${clientId}/comments`).then((resp) => resp?.data);
};

export const addClientComment = (clientId, comments) => {
  return axiosClient
    .post(`v1/client/${clientId}/comments`, { comments })
    .then((resp) => resp?.data);
};

export const checkIfEmailExists = ({ email }) => {
  return axiosClient
    .post(checkIfEmailExistsEndpoint, {
      email
    })
    .then((resp) => resp?.data);
};

export const checkIfMobileExists = ({ country_code, mobile }) => {
  return axiosClient
    .post(checkIfMobileExistsEndpoint, {
      country_code,
      mobile
    })
    .then((resp) => resp?.data);
};

export const getPayments = (id) => {
  return axiosClient.get('v1/users/payments/status/' + id).then((resp) => resp?.data);
};

export const addPaymentDetails = (formData) => {
  return axiosClient
    .post('v1/client/payments/fileupload?encoded=false', formData)
    .then((resp) => resp?.data);
};

export const updatePaymentDetails = (formData, paymentId) => {
  return axiosClient
    .put(`v1/client/payments/update?payment_id=${paymentId}`, formData)
    .then((resp) => resp?.data);
};

export const updatePremiumType = (clientId, premiumType) => {
  return axiosClient
    .put(`v1/client/${clientId}/update/premium`, { premium_type: premiumType })
    .then((resp) => resp?.data);
};

export const getPayment = (clientId, paymentId) => {
  return axiosClient
    .get(`v1/users/payments/${clientId}?payment_id=${paymentId}`)
    .then((resp) => resp?.data);
};

export const getEmployeeSearch = (limit = 10000, offset = 0, searchParam) => {
  return axiosClient
    .get(`v1/employee/search?limit=${limit}&offset=${offset}&search_param=${searchParam}`)
    .then((resp) => resp.data);
};

export const getFaqDropdown = () => {
  return axiosClient.get(faqEndPoint).then((resp) => resp.data);
};

export const verifyClient = (clientId) => {
  return axiosClient.post(`v2/client/${clientId}/email/verify`).then((resp) => resp?.data);
};

export const getARCALogs = (clientId) => {
  return axiosClient.get(`v2/client/${clientId}/client_log`).then((resp) => resp?.data);
};

export const addARCALogs = (clientId, params) => {
  return axiosClient.post(`v2/client/${clientId}/client_log`, params).then((resp) => resp?.data);
};

export const ARCADropdown = () => {
  return axiosClient.get('v2/arca/dropdown/values').then((resp) => resp.data);
};

export const getARCAFunds = (clientId) => {
  return axiosClient.get(`v2/client/${clientId}/fund`).then((resp) => resp.data);
};

export const addARCAFund = (clientId, params) => {
  return axiosClient.post(`v2/client/${clientId}/fund`, params).then((resp) => resp?.data);
};

export const addARCAOrder = (clientId) => {
  return axiosClient.post(`v2/client/${clientId}/fund/order`).then((resp) => resp?.data);
};

export const getARCAOrder = (clientId) => {
  return axiosClient.get(`v2/client/${clientId}/fund/order`).then((resp) => resp?.data);
};

export const updateARCALog = (clientId, logId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/client_log/${logId}/update`, params)
    .then((resp) => resp?.data);
};
