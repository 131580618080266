/* eslint-disable no-unused-vars */

import moment from 'moment';
import { axiosClient } from 'utils/axiosClient';

const getDashboardEndpoint = 'v2/employee/insights';
const getAumPrmEndpoint = 'v2/employee/insights/aum/prm';
const getAumBranchEndpoint = 'v2/employee/insights/aum/branch';
const getRedBoxEndpoint = 'v2/employee/insights/redbox';
const getRevenueEndpoint = 'v2/employee/insights/revenue';
const getDSREndpoint = 'v2/employee/insights/dsr';
const getRevenueCollectedEndpoint = 'v2/employee/insights/revenue/collected_by';
const getBuyersEndpoint = 'v2/employee/insights/buyers';
const getExpiringAdsEndpoint = 'v2/employee/insights/expiringads';
const getRevenuePipelineEndpoint = '/v2/employee/insights/revenue/pipeline';
const getRevenueBookedEndpoint = '/v2/clients/revenue';
const getRevenueInsightsEndpoint = 'v2/employee/insights/revenue_projection';

export const getInsights = (fromDate, toDate, rm, prm, bh, branch, customerType) => {
  const params = {
    bh: bh,
    branch: branch,
    customer_type: customerType,
    from_date: fromDate,
    prm: prm,
    rm: rm,
    to_date: toDate
  };

  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(getDashboardEndpoint + queryString).then((resp) => resp?.data);
};

export const getAumPrm = (fromDate, toDate, branch) => {
  const params = {
    branch: branch,
    from_date: fromDate,
    to_date: toDate
  };

  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(getAumPrmEndpoint + queryString).then((resp) => resp?.data);
};

export const getAumBranch = (fromDate, toDate, prm) => {
  const params = {
    from_date: fromDate,
    prm: prm,
    to_date: toDate
  };

  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(getAumBranchEndpoint + queryString).then((resp) => resp?.data);
};

export const getRedBoxData = (fromDate, toDate, rm, prm, bh, branch) => {
  const params = {
    bh: bh,
    branch: branch,
    from_date: fromDate,
    prm: prm,
    rm: rm,
    to_date: toDate
  };

  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(getRedBoxEndpoint + queryString).then((resp) => resp?.data);
};

export const getRevenue = (fromDate, toDate, rm, prm, bh, branch) => {
  const params = {
    bh: bh,
    branch: branch,
    from_date: (fromDate === '2000-01-01' ? '2023-01-01' : fromDate) || '2023-01-01',
    prm: prm,
    rm: rm,
    to_date: toDate || moment().format('YYYY-MM-DD')
  };

  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(getRevenueEndpoint + queryString).then((resp) => resp?.data);
};

export const getRevenueByPRM = (fromDate, toDate, rm, prm, bh, branch) => {
  const params = {
    bh: bh,
    branch: branch,
    from_date: (fromDate === '2000-01-01' ? '2023-01-01' : fromDate) || '2023-01-01',
    prm: prm,
    rm: rm,
    to_date: toDate || moment().format('YYYY-MM-DD')
  };

  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient
    .get(getRevenueEndpoint + '/splits_prm' + queryString)
    .then((resp) => resp?.data);
};

export const getRevenueByRM = (fromDate, toDate, rm, prm, bh, branch) => {
  const params = {
    bh: bh,
    branch: branch,
    from_date: (fromDate === '2000-01-01' ? '2023-01-01' : fromDate) || '2023-01-01',
    prm: prm,
    rm: rm,
    to_date: toDate || moment().format('YYYY-MM-DD')
  };

  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient
    .get(getRevenueEndpoint + '/splits_rm' + queryString)
    .then((resp) => resp?.data);
};

export const getRevenueCollected = (fromDate, toDate, rm, prm, bh, branch) => {
  const params = {
    bh: bh,
    branch: branch,
    from_date: fromDate || '2000-01-01',
    prm: prm,
    rm: rm,
    to_date: toDate || moment().format('YYYY-MM-DD')
  };

  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(getRevenueCollectedEndpoint + queryString).then((resp) => resp?.data);
};

export const getDSRData = (fromDate, toDate, rm, prm, bh, branch) => {
  const params = {
    bh: bh,
    branch: branch,
    from_date: fromDate,
    prm: prm,
    rm: rm,
    to_date: toDate
  };

  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(getDSREndpoint + queryString).then((resp) => resp?.data);
};

export const getBuyersCount = (fromDate, toDate, rm, prm, bh, branch) => {
  const params = {
    bh: bh,
    branch: branch,
    from_date: fromDate,
    prm: prm,
    rm: rm,
    to_date: toDate
  };

  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(getBuyersEndpoint + queryString).then((resp) => resp?.data);
};

export const getExpiringAds = (rm, prm, bh, branch, date) => {
  const params = {
    bh: bh,
    branch: branch,
    date: date || moment().format('YYYY-MM-DD'),
    prm: prm,
    rm: rm
  };

  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(getExpiringAdsEndpoint + queryString).then((resp) => resp?.data);
};

export const getRevenuePipeline = (fromDate, toDate, rm, prm, bh, branch) => {
  const params = {
    bh: bh,
    branch: branch,
    from_date: fromDate,
    prm: prm,
    rm: rm,
    to_date: toDate
  };

  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(getRevenuePipelineEndpoint + queryString).then((resp) => resp?.data);
};

export const getRevenueBooked = (filters) => {
  const params = {
    branch: filters?.branch,
    branch_head: filters?.branchHead,
    customer_type: filters?.customerType,
    from_date: filters?.createdFromDate,
    limit: filters?.limit,
    offset: filters?.limit * filters?.page,
    portfolio_manager: filters?.rewm,
    revenue_type: filters?.revenueType,
    rm_name: filters?.rm,
    sort_at: filters?.sortAt,
    sort_by: filters?.sortBy,
    source: filters?.source,
    to_date: filters?.createdToDate
  };

  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(getRevenueBookedEndpoint + queryString).then((resp) => resp?.data);
};

export const getRevenueInsights = (fromDate, toDate, rm, prm, bh, branch, projectionStatus) => {
  const params = {
    bh: bh,
    branch: branch,
    from_date: fromDate,
    prm: prm,
    projection_status: projectionStatus,
    rm: rm,
    to_date: toDate
  };

  const query = Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(getRevenueInsightsEndpoint + queryString).then((resp) => resp?.data);
};
